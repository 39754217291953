import { render, staticRenderFns } from "./newsList.vue?vue&type=template&id=0540eaef&scoped=true"
import script from "./newsList.vue?vue&type=script&lang=js"
export * from "./newsList.vue?vue&type=script&lang=js"
import style0 from "./newsList.vue?vue&type=style&index=0&id=0540eaef&prod&lang=less&scoped=true"
import style1 from "./newsList.vue?vue&type=style&index=1&id=0540eaef&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0540eaef",
  null
  
)

export default component.exports