<template>
  <!-- 消息管理 - 消息列表 -->
  <div class="pageContol newsList">
    <div class="framePage">
      <!-- 面包屑 -->
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">消息管理</a>
          <i class="el-icon-arrow-right"></i>
          <a href="javascript:;" @click="init" class="cur-a">消息列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <!-- 检索条件 -->
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:5px">
            <el-form ref="seachData" :model="seachData" inline label-width="70px">
              <el-form-item>
                <el-button
                  type="primary"
                  style="margin-left:20px"
                  :class="show1?'':'bgc-bv'"
                  :disabled="show1"
                  @click="batchDelete"
                  round
                >删除</el-button>
                <el-button
                  type="primary"
                  style="margin-left:20px"
                  :class="show1?'':'bgc-bv'"
                  :disabled="show1"
                  @click="batchDeleteRead"
                  round
                >标记已读</el-button>
                <el-button style="margin-left:20px" class="bgc-bv" @click="allRead" round>全部已读</el-button>
              </el-form-item>
              <el-form-item label="标题" style="float:right;">
                <el-select
                  v-model="seachData.msgName"
                  clearable
                  size="small"
                  placeholder="请选择状态"
                  style
                  @change="getData()"
                >
                  <el-option
                    v-for="(item,index) in listArr"
                    :key="index"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="状态" style="float:right;">
                <el-select
                  v-model="seachData.readType"
                  clearable
                  size="small"
                  placeholder="请选择状态"
                  style
                  @change="getData()"
                >
                  <el-option
                    v-for="item in readTypes"
                    :key="item.value"
                    :label="item.lable"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 列表 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              @selection-change="handleSelectionChange"
              stripe
            >
              <el-table-column align="center" type="selection" min-width="55"></el-table-column>
              <el-table-column
                label="状态"
                 align="left"
                show-overflow-tooltip
                prop="msgState"
                min-width="50"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.msgState == '30'">
                    <i class="bjInfo1"></i>已读
                  </span>
                  <span v-else-if="scope.row.msgState == '10'">
                    <i class="bjInfo2"></i>未读
                  </span>
                  <span v-else>
                    <i class="bjInfo3"></i>未知
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="标题"
                prop="msgName"
                show-overflow-tooltip
                min-width="150"
              ></el-table-column>
              <el-table-column
                label="内容"
                prop="msgContent"
                show-overflow-tooltip
                min-width="250"
              ></el-table-column>
              <el-table-column
                label="时间"
                prop="createTime"           
                show-overflow-tooltip
                min-width="150"
              ></el-table-column>
              <el-table-column
                label="操作"
                align="center"
                prop="deviceModel"
                show-overflow-tooltip
                min-width="80"
              >
                <template slot-scope="scope">
                  <el-button type="text" size="mini" @click="seeInfo(scope.row)">详情</el-button>
                  <el-button
                    type="text"
                    size="mini"
                    @click="deleteData(scope.row)"
                    style="margin-left:5px!important;"
                  >删除</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <!-- 分页 -->
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue"; // 引入表格无数据提示 - 组件
import PageNum from "@/components/PageNum.vue"; // 引入分页 - 组件
import List from "@/mixins/List"; // 引入列表 - 混入
export default {
  name: "newsList",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      seachData: {
        readType: "" // 已读类型
      },
      // 反馈类型 - 下拉数据
      readTypes: [
        {
          value: "",
          lable: "全部"
        },
        {
          value: 30,
          lable: "已读"
        },
        {
          value: 10,
          lable: "未读"
        }
      ],
      // 已勾选的数据
      multipleSelection: [],
      // 判断-已勾选的数据是否有，有可以做批量处理
      show1: true,
      listArr:[]
    };
  },
  mounted() {},
  computed: {},
  watch: {
    // 监听 - 已勾选的数据；有数据 删除/标记已读 可以点击
    multipleSelection: {
      handler(newName, oldName) {
        if (newName.length > 0) {
          this.show1 = false;
        } else {
          this.show1 = true;
        }
      },
      // 立即先去执行里面的handler方法 - 类似初始化就执行
      immediate: true,
      // 开启深度监听
      deep: true
    }
  },
  created() {
    this.geList()
  },
  methods: {
    // 获取数据
    getData(pageNum = 1) {
      const params = {
        msgState: this.seachData.readType,
        pageNum: pageNum,
        pageSize: this.pageSize,
        msgName:this.seachData.msgName
      };
      this.doFetch({
        url: "/message/queryMessageList",
        params,
        pageNum
      });
    },
    // 查看详情
    seeInfo(row) {
      this.$router.push({
        path: "/web/newsListInfo",
        query: {
          newsInfo: JSON.stringify(row)
        }
      });
    },
    // 删除
    deleteData(row) {
      this.$confirm("是否要删除该条消息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let pushData = {
            ids: [Number(row.msgId)],
            msgState: "20"
          };
          this.$post("/message/batchUpdateMagState", pushData).then(res => {
            this.$message.success(res.message);
            this.getData();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 复选框勾选更多
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量删除
    batchDelete() {
      this.$confirm("是否要删除已选中的消息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let arrData = [];
          for (let i = 0; i < this.multipleSelection.length; i++) {
            arrData.push(Number(this.multipleSelection[i].msgId));
          }
          let pushData = {
            ids: arrData,
            msgState: "20"
          };
          this.$post("/message/batchUpdateMagState", pushData).then(res => {
            this.$message.success(res.message);
            this.getData();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 批量标记已读
    batchDeleteRead() {
      let arrData = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        arrData.push(Number(this.multipleSelection[i].msgId));
      }
      let pushData = {
        ids: arrData,
        msgState: "30"
      };
      this.$post("/message/batchUpdateMagState", pushData).then(res => {
        this.$message.success(res.message);
        this.getData();
      });
    },
    // 全部已读
    allRead() {
      this.$post("/message/readAllMsg").then(res => {
        this.$message.success(res.message);
        this.getData();
      });
    },
    geList() {
      this.$post('/message/title/list').then(ret => {
        this.listArr = ret.data
      }).catch(err => {
        return
      })
    }
  }
};
</script>

<style lang="less" scoped>
.newsList /deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
}
.searchbox {
  width: 100%;
}
.newsList {
  .el-form {
    width: 100%;
  }
  .el-form-item {
    margin-bottom: 0;
    span {
      color: #909399;
      font-size: 12px;
    }
  }
  .framePage-scroll {
    .bjInfo1 {
      background: #7f7f7f;
      width: 5px;
      height: 5px;
      display: inline-block;
      border-radius: 50%;
      margin: 0 5px;
      vertical-align: middle;
      margin-top: -3px;
    }
    .bjInfo2 {
      background: red;
      width: 5px;
      height: 5px;
      display: inline-block;
      border-radius: 50%;
      margin: 0 5px;
      vertical-align: middle;
      margin-top: -3px;
    }
    .bjInfo3 {
      background: #e6a23c;
      width: 5px;
      height: 5px;
      display: inline-block;
      border-radius: 50%;
      margin: 0 5px;
      vertical-align: middle;
      margin-top: -3px;
    }
  }
    .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.newsList /deep/ .el-dialog__body {
  overflow: hidden;
  .block {
    width: 100%;
    .el-image {
      width: 48% !important;
      float: left;
      margin-bottom: 8px;
      margin-left: 1.5%;
    }
  }
}
</style>
<style>
.el-tooltip__popper {
  max-width: 35% !important;
}
</style>
